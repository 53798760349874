import React from 'react'
import { initializeStore } from '../store'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

function getInitialState() {
  return undefined
}

const withRedux = App => {
  return class AppWithRedux extends React.Component {
    static async getStaticProps(appContext) {
      const reduxStore = getOrCreateStore(
        isServer ? getInitialState() : undefined
      )

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}
      if (typeof App.getStaticProps === 'function') {
        appProps = await App.getStaticProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      }
    }

    // we include getInitialProps because we want to opt-out of static optimisation, as
    // the medshr hostnames are configured using environment variables so mustn't be hard-coded
    // into generated html+js
    static async getInitialProps(appContext) {
      if (typeof App.getInitialProps === 'function') {
        return App.getInitialProps(appContext)
      }
      return {}
    }

    constructor(props) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  }
}

export default withRedux
