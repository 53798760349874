import {
  createStore,
  StoreCreator,
  applyMiddleware,
  combineReducers,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  assocPath,
  dissocPath,
  equals,
  map,
  path,
  pickBy,
  fromPairs,
  toPairs,
  without,
} from 'ramda'
import { notificationsReducer } from './models/notifications'
import { realtimeRedux } from './models/realtime'

const combinedReducers = combineReducers({
  auth: (state = {}) => state,
  api: (state = {}) => state,
  entities: (state = {}) => state,
  notifications: notificationsReducer,
  realtime: realtimeRedux.reducer,
})

const merge = (current, v) => {
  if (v.constructor === Array) {
    return v
  }
  return { ...(current || {}), ...v }
}

const rootReducer = (state, action) => {
  state = combinedReducers(state, action)
  if (action.type === 'UPDATE') {
    state = assocPath(action.path, action.value, state)
  } else if (action.type === 'TRANSFORM') {
    state = assocPath(
      action.path,
      action.transform(path(action.path, state)),
      state
    )
  } else if (action.type === 'TOUCH') {
    state = assocPath(
      action.path,
      Object.assign({}, path(action.path, state)),
      state
    )
  } else if (action.type === 'DELETE') {
    state = dissocPath(action.path, state)
  } else if (action.type === 'DELETE_PREFIX') {
    const current = path(action.path, state) || {}
    state = assocPath(
      action.path,
      pickBy((_, k) => !k.startsWith(action.prefix), current),
      state
    )
  } else if (action.type === 'MERGE_DEEP') {
    const current: any = path(action.path, state) || {}
    const merged = {
      ...current,
      ...fromPairs(
        map(([k, v]) => [k, merge(current[k], v)], toPairs(action.value))
      ),
    }
    state = assocPath(action.path, merged, state)
  } else if (action.type === 'LIST_REMOVE') {
    const current: any[] = path(action.path, state) || []
    state = assocPath(action.path, without([action.value], current), state)
  }
  return state
}

export const initializeStore: StoreCreator = (
  initialState: any = { entities: {}, notifications: {} }
) => {
  const socket = realtimeRedux()

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(socket.middleware))
  )
  if (typeof window !== 'undefined') {
    socket.connect(store.dispatch)
  }
  return store
}
