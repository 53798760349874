import {
  createContext,
  createElement,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { bind, findIndex, forEach, path } from 'ramda'
import getConfig from 'next/config'
import SockJS from 'sockjs-client'

const config = getConfig().publicRuntimeConfig

type RealtimeMessage = {
  _id: number
  channel: string
  message: {
    type: string
  }
}

type RealtimeState = {
  inbox: RealtimeMessage[]
  totalReceived: number
  totalSent: number
  reconnections: number
}

type RealtimeHandlers = {
  open?: () => void
  close?: () => void
  message?: (data: any) => void
}

type SendFunction = (message: any) => void

const ACTION_CONNECT = 'realtime/CONNECT'
const ACTION_SEND = 'realtime/SEND'
const ACTION_RECV = 'realtime/RECV'

const createSendAction = message => ({ type: ACTION_SEND, message })
const createRecvAction = message => ({ type: ACTION_RECV, message })

const useRealtime = (): [RealtimeState, SendFunction] => {
  const dispatch = useDispatch()
  const state: RealtimeState = useSelector(path(['realtime']))
  const send = useCallback(message => dispatch(createSendAction(message)), [
    state.reconnections,
  ])
  return [state, send]
}

export const useRealtimeSubscription = (
  channel: string,
  listenerP: (action: any) => void
) => {
  const [rt, send] = useRealtime()
  const [lastHit, setLastHit] = useState(null)
  const listener = useCallback(listenerP, [channel])

  useEffect(() => {
    send({ type: 'subscribe', channel })
    return () => send({ type: 'unsubscribe', channel })
  }, [channel, send])

  useEffect(() => {
    if (rt.inbox.length > 0) {
      const i = lastHit ? findIndex(x => x._id === lastHit, rt.inbox) : -1
      forEach(
        x => (x.channel === channel ? listener(x.message) : undefined),
        i === -1 ? rt.inbox : rt.inbox.slice(i + 1)
      )
      setLastHit(rt.inbox[rt.inbox.length - 1]._id)
    }
  }, [rt.totalReceived])
}

const connect = (handlers: RealtimeHandlers): Promise<SendFunction> =>
  new Promise((resolve, reject) => {
    if (config.socketServerUrl && SockJS) {
      const sockjs = new SockJS(config.socketServerUrl, undefined, {
        transports: [
          'websocket',
          'xhr-streaming',
          'eventsource',
          'iframe-eventsource',
          'xhr-polling',
          'iframe-xhr-polling',
        ],
      })

      sockjs.onopen = function() {
        console.log('[*] open', sockjs.protocol)
        if (handlers.open) {
          handlers.open()
        }
        resolve(message => sockjs.send(JSON.stringify(message)))
      }
      sockjs.onmessage = function(e) {
        console.log('[.] message', e.data)
        if (handlers.message) {
          handlers.message(JSON.parse(e.data))
        }
      }
      sockjs.onclose = function() {
        console.log('[*] close')
        if (handlers.close) {
          handlers.close()
        }
      }

      if (process.env.MEDSHR_ENVIRONMENT === 'local') {
        const win: any = global
        win.sockjs = sockjs
      }

      return sockjs
    } else {
      reject(new Error('realtime - not configured'))
    }
  })

export const realtimeRedux = () => {
  let send
  let connecting
  let queue = []
  let backoff = 1

  const checkConnected = dispatch => {
    if (send) {
      return Promise.resolve(send)
    }

    connecting = true

    return connect({
      close: () => {
        send = undefined
        setTimeout(() => {
          checkConnected(dispatch)
          backoff *= 1.5
        }, backoff * 1000)
      },
      message: message => dispatch({ type: ACTION_RECV, message }),
    }).then(s => {
      dispatch({ type: ACTION_CONNECT })
      send = s
      forEach(action => send(action.message), queue)
      queue = []
      connecting = false
      backoff = 1
      return s
    })
  }

  return {
    middleware: ({ getState, dispatch }) => next => action => {
      if (action.type === ACTION_SEND) {
        if (connecting) {
          queue.push(action)
        } else {
          checkConnected(dispatch).then(send => send(action.message))
        }
      }
      const result = next(action)
      return result
    },

    connect: checkConnected,
  }
}

const actions = {
  [ACTION_CONNECT]: (state, action) => ({
    ...state,
    reconnections: state.reconnections + 1,
  }),
  [ACTION_RECV]: (state, action) => ({
    ...state,
    totalReceived: state.totalReceived + 1,
    inbox: state.inbox.concat([
      { _id: state.totalReceived, ...action.message },
    ]),
  }),
  [ACTION_SEND]: (state, action) => ({
    ...state,
    totalSent: state.totalSent + 1,
  }),
}

realtimeRedux.reducer = (
  state: RealtimeState = {
    totalReceived: 0,
    totalSent: 0,
    inbox: [],
    reconnections: 0,
  },
  action?: { type: string }
): RealtimeState =>
  action && actions[action.type] ? actions[action.type](state, action) : state
