import React from 'react'
import { createMedia } from '@artsy/fresnel'

const breakpoints = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920,
}

const AppMedia = createMedia({
  breakpoints,
})

const mediaStyles = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia

export enum Comparison {
  LessThan = -1,
  Equal = 0,
  GreaterThan = 1,
}

export const compareMedia = (
  width: number,
  breakpoint: keyof typeof breakpoints
): Comparison => {
  const d = width - breakpoints[breakpoint]
  return d > 0
    ? Comparison.GreaterThan
    : d < 0
    ? Comparison.LessThan
    : Comparison.Equal
}

export const mediaLessThan = (
  width: number,
  breakpoint: keyof typeof breakpoints
): boolean => Comparison.LessThan === compareMedia(width, breakpoint)

export const mediaGreaterThan = (
  width: number,
  breakpoint: keyof typeof breakpoints
): boolean => Comparison.GreaterThan === compareMedia(width, breakpoint)

export const MediaProvider = ({ children }) => (
  <>
    <style>{mediaStyles}</style>
    <MediaContextProvider>{children}</MediaContextProvider>
  </>
)

export default Media
