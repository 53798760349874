import { useEffect } from 'react'
import { filter, map, path, reverse } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import { objectID } from './object'

const NOTE_DISPLAY = 'NOTE_DISPLAY'
const NOTE_REMOVE = 'NOTE_REMOVE'
const NOTE_CLEANUP = 'NOTE_CLEANUP'

export const displayNotification = message => ({
  type: NOTE_DISPLAY,
  message,
})

export const removeNotification = message => ({
  type: NOTE_REMOVE,
  message_id: objectID(message),
})

export const useNotifier = () => {
  const dispatch = useDispatch()
  return message => dispatch(displayNotification(message))
}

export const useNotifications = () => {
  const dispatch = useDispatch()
  const notifications = reverse(
    useSelector(path(['notifications', 'stack'])) || []
  )
  useEffect(() => {
    if (notifications.length) {
      const timer = setInterval(() => dispatch({ type: NOTE_CLEANUP }), 1000)
      return () => clearInterval(timer)
    }
  }, [notifications])
  return notifications
}

const actions = {
  [NOTE_DISPLAY]: (state, action) => ({
    ...state,
    stack: [
      {
        ...action.message,
        id: action.message.id || `m${new Date().getTime()}`,
        visible: true,
        expiry: new Date().getTime() + (action.message.ttl || 5000),
      },
    ].concat(state.stack || []),
  }),
  [NOTE_REMOVE]: (state, action) => ({
    ...state,
    stack: map(
      message =>
        message.id === action.message_id
          ? { ...message, visible: false }
          : message,
      state.stack || []
    ),
  }),
  [NOTE_CLEANUP]: (state, action) => ({
    ...state,
    stack: filter(
      message => message.expiry > new Date().getTime() - 5000,
      map(
        message =>
          message.expiry < new Date().getTime()
            ? { ...message, visible: false }
            : message,
        state.stack || []
      )
    ),
  }),
}

export const notificationsReducer = (state = { stack: [] }, action = {}) =>
  actions[action.type] ? actions[action.type](state, action) : state
