import App from 'next/app'
import Head from 'next/head'
import getConfig from 'next/config'

import React from 'react'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/browser'

import withReduxStore from '../lib/with-redux-store'
import { MediaProvider } from '../components/Responsive'
import '../style/index.scss'

const siteConfig = getConfig().publicRuntimeConfig
if (siteConfig.sentry_dsn && siteConfig.sentry_dsn.length > 0) {
  Sentry.init({
    dsn: siteConfig.sentry_dsn,
    environment: siteConfig.env,

    // To set a uniform sample rate - set to 1 for testing
    // (should lower to something like 0.2 in production
    tracesSampleRate: siteConfig.tracesSampleRate ?? 1.0,
  })
}

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props
    return (
      <Provider store={reduxStore}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <MediaProvider>
          <Component {...pageProps} />
        </MediaProvider>
      </Provider>
    )
  }
}

export default withReduxStore(MyApp)
